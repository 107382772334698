import React, { useState } from "react";
import axios from "axios";
import logo from "./img/logo.png"

import './App.css';

export default function App(){
    const [advice,setAdvice] = useState ("")
    function fetchAdvice(){
        axios.get('https://api.adviceslip.com/advice')
        .then((response) => {
            const advice = response.data.slip.advice
            setAdvice(advice)
        }).catch((error)=>{
            console.error(error)
        })
    }
    fetchAdvice()
    return (
        <>
        <div className="nav_logo" style={{height: "8%",width:"100%",backgroundColor:"white"}}>
            <img src={logo} alt="LOGO" style={{height:"50px", marginTop:"0.15%",marginLeft:"41.3%"}}/>
        </div>
        <div className="app">
        <div className="card">
          <h1 className="heading">{advice}</h1>
          <button className="button" onClick={fetchAdvice}>
            <span>Advice for the Day !!!</span>
          </button>
        </div>
      </div>
        </>
    )
}